import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import MyImg from '../../MyImg';
import generateImgAltText from '../../../Utils/GenerateImgAltText';
import slugFormat from '../../../Utils/SlugFormat';
import TitleBgFilled from '../../TitleBgFilled';

const StyledCard = styled(Card)`
  position: relative;
  transition: transform 1s, opacity 1s;

  &:hover .image-gallery {
    opacity: 0.15;
  }

  &:hover .middle {
    opacity: 1;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
  }

  .image-gallery {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .row-gallery {
    margin: 3rem !important;
  }

  &.section--hidden {
    opacity: 0;
    transform: translateY(8rem);
  }

  @media only screen and (max-width: 600px) {
    .row-gallery {
      margin: 0 !important;
    }
  }
`;

function ArtData({ title, text }: { title: string; text: string }) {
  return (
    <Typography>
      <strong>{title}</strong> {text}
      <br />
    </Typography>
  );
}

export default function GalleryCard({
  obra,
  sectionObserver,
}: {
  obra: any;
  sectionObserver: IntersectionObserver | null;
}) {
  const { img, sold } = obra;
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) sectionObserver?.observe(ref.current);
  }, [ref, sectionObserver]);

  return (
    <StyledCard ref={ref} className="section--hidden">
      <Link to={`/galeria/${slugFormat(img)}/`} className="text-decoration-none text-reset">
        <MyImg name={img} className="img-thumbnail image-gallery" alt={generateImgAltText(obra)} />
      </Link>
      {sold === 1 && <h6 className="sold">Vendido</h6>}
      <div className="middle">
        <Link to={`/galeria/${slugFormat(obra.img)}/`} className="text-decoration-none text-reset">
          <TitleBgFilled text={obra.title} />
          <p className="py-2">
            <ArtData title="Técnica:" text={obra.technique} />
            <ArtData title="Año:" text={obra.year} />
            {obra.dimensions && <ArtData title="Dimensiones:" text={obra.dimensions} />}
            {obra.sold === 0 && <ArtData title="Precio:" text={obra.price} />}
          </p>
        </Link>
      </div>
    </StyledCard>
  );
}
