import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { CardColumns, Container } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ParalaxTitle from '../components/ParalaxTitle';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import imgBackground from '../images/2.jpeg';
import GalleryCard from '../components/styles/Gallery/GalleryCard';

export default function Gallery() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allArt {
        edges {
          node {
            dimensions
            price
            technique
            title
            year
            img
            description
            sold
            style
          }
        }
      }
    }
  `);
  const obras = data.allArt.edges;

  const revealSection = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      entry.target.classList.remove('section--hidden');
      observer.unobserve(entry.target);
    });
  };
  const isBrowser = typeof window !== 'undefined';
  const sectionObserver = useRef(
    isBrowser
      ? new IntersectionObserver(revealSection, {
          root: null,
          threshold: 0.15,
        })
      : null
  );

  return (
    <Layout>
      <SEO title="Galería" />
      <ParalaxTitle text="Galería" imgBackground={imgBackground} />
      <Container>
        <CardColumns>
          {obras.map((obra, index) => (
            <GalleryCard obra={obra.node} key={index} sectionObserver={sectionObserver.current} />
          ))}
        </CardColumns>
      </Container>
    </Layout>
  );
}
